<template>
  <header
    class="relative flex flex-col pt-navigation-mobile sm:pt-navigation xl:min-h-screen"
    :class="showBlueSickle ? 'mb-12' : 'mb-20 sm:mb-24 xl:mb-28'"
  >
    <div class="grid grow grid-cols-1 overflow-hidden xl:grid-cols-2">
      <!-- TEXT -->

      <div
        class="flex flex-col justify-between px-container pt-20 sm:px-container-sm xl:pb-16 xl:pl-container-xl xl:pr-16 xl:pt-[9.5rem]"
      >
        <div>
          <InViewAnimate>
            <h1
              class="font-sans-heavy-900 mb-5 max-w-title text-2xl sm:text-4xl"
              v-html="$replaceHtmlTags(data.heading)"
            />
          </InViewAnimate>

          <InViewAnimate :delay="200">
            <div
              class="text max-w-text text-base sm:text-lead"
              v-html="data.text"
            />
          </InViewAnimate>

          <InViewAnimate v-if="data.link" :delay="400">
            <AppButton class="my-9 xl:my-16" :to="data.link">
              {{ data.link_text }}
            </AppButton>
          </InViewAnimate>
        </div>
        <!-- SCROLLY DESKTOP -->
        <Scrolly
          class="hidden w-fit rounded-[1em] xl:flex"
          :class="
            showBlueSickle ? 'text-white 3xl:text-main-blue' : 'text-main-blue'
          "
        >
          <span
            :class="{
              'text-white 3xl:text-main-blue-dark-hover': showBlueSickle,
            }"
          >
            {{ data.scroll_button_text }}
          </span>
        </Scrolly>
      </div>

      <!-- IMAGE OR LOTTIE -->
      <div
        class="-mr-10 pl-container sm:pl-container-sm xl:-ml-16 xl:-mr-3 xl:pl-0 xl:pt-11"
      >
        <div v-if="data.image_lottie?.extension === 'json'" class="relative">
          <ClientOnly>
            <Vue3Lottie
              :animation-link="data.image_lottie.url"
              class="pointer-events-none xl:max-h-[calc(100vh-6rem-3rem)]"
            />
          </ClientOnly>
        </div>

        <AppImage
          v-else
          class="xl:max-h-[calc(100vh-6rem-3rem)]"
          :src="data.image_lottie"
        />
      </div>
    </div>
    <!-- SCROLLY MOBILE -->
    <Scrolly
      class="my-7 w-fit rounded-[1em] px-container sm:px-container-sm xl:hidden xl:px-container-xl"
      :class="showBlueSickle ? 'text-white' : 'text-main-blue'"
    >
      <span :class="{ 'text-white': showBlueSickle }">
        {{ data.scroll_button_text }}
      </span>
    </Scrolly>

    <!-- BLUE SICKLE -->
    <div
      v-if="showBlueSickle"
      class="pointer-events-none absolute inset-0 -bottom-12 overflow-hidden"
    >
      <SickleIcon
        class="absolute bottom-[calc(-35vw+15%)] left-[-12vw] -z-10 mr-[-30%] text-main-blue xs:bottom-[calc(-38vw+15%)] xs:left-[-15vw] 3xl:bottom-[-40vw] 4xl:bottom-[-41vw] 4xl:left-[-17.5vw]"
      />
      <div
        class="absolute inset-x-0 bottom-0 -z-10 h-[15%] bg-main-blue 3xl:hidden"
      />
    </div>
  </header>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
export default {
  components: {
    Vue3Lottie,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    firstComponent: {
      type: Object,
      default: null,
    },
  },

  computed: {
    showBlueSickle() {
      return (
        this.firstComponent.type === 'teasers_text_and_image' &&
        this.firstComponent.style?.value === 'blue'
      )
    },
  },
}
</script>
