<template>
  <SectionY :padding="data.padding?.value">
    <ContainerX>
      <div class="mx-auto max-w-component">
        <h2
          v-if="data.heading"
          class="default-component-title mx-auto mb-14 xl:mb-20"
        >
          <span v-html="$replaceHtmlTags(data.heading)" />
        </h2>
        <ul class="grid gap-6">
          <!-- ACCORDION -->
          <li v-for="(accordion, index) in data.accordion_group" :key="index">
            <InViewAnimate>
              <Accordion
                class="rounded-lg shadow-custom"
                :duration-open="300"
                :is-open="index === openAccordionIndex"
                @open="openAccordionIndex = index"
                @close="
                  index === openAccordionIndex
                    ? (openAccordionIndex = null)
                    : null
                "
              >
                <template #summary>
                  <div
                    class="font-sans-heavy-900 max-w-title p-5 text-base sm:p-6 sm:pr-28 sm:text-md"
                  >
                    {{ accordion.heading }}
                  </div>
                </template>
                <template #content>
                  <div
                    class="text pb-7 pl-5 pr-20 text-sm sm:pb-9 sm:pl-6 sm:pr-28 sm:text-base"
                    v-html="accordion.text"
                  />
                </template>
              </Accordion>
            </InViewAnimate>
          </li>
        </ul>
      </div>
    </ContainerX>
  </SectionY>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      openAccordionIndex: null,
    }
  },
}
</script>
