import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["Countries","Entry","Locations","NavigationAndGlobals","Partners","Testimonials","ReplicatorComponents","Asset","Hero","Partner","Testimonial","BlueprintPage"]}
export const GqlCountries = (...params) => useGql()('Countries', ...params)
export const GqlEntry = (...params) => useGql()('Entry', ...params)
export const GqlLocations = (...params) => useGql()('Locations', ...params)
export const GqlNavigationAndGlobals = (...params) => useGql()('NavigationAndGlobals', ...params)
export const GqlPartners = (...params) => useGql()('Partners', ...params)
export const GqlTestimonials = (...params) => useGql()('Testimonials', ...params)