export default defineNuxtPlugin(() => {
  return {
    provide: {
      replaceHtmlTags: (html: string) => {
        if (!html) return null

        // replace <em> with <ColorFullText>
        html = html.replace(/<\s*\/?\s*em\s*>/g, function (match) {
          if (match === '<em>') {
            return '<ColorFulText>'
          } else {
            return '</ColorFulText>'
          }
        })
        // replace <p> with '' and <br>
        return html
          .replace(/<\/p[^>]*><p[^>]*>/g, '<br>')
          .replace(/<\/?p[^>]*>/g, '')
      },

      isExternalUrl: (url: string) => {
        if (!url) return null
        const regex = /^(https?:\/\/)/
        return regex.test(url)
      },
    },
  }
})
