<template>
  <Component
    v-bind="$attrs"
    :is="getType(component)"
    v-for="(component, index) in components"
    :key="index"
    :data="component"
    :component-index="index + 1"
    :prev-component="components[index - 1]?.type"
    :next-component="components[index + 1]?.type"
  />
</template>

<script>
export default {
  props: {
    components: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getType(component) {
      if (!component.type) {
        console.error('ComponentLoader: Component has no type', component)
        return null
      }

      return component.type.replaceAll(/_/g, '-')
    },
  },
}
</script>
