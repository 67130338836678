<template>
  <figure>
    <div class="group relative aspect-video h-full w-full">
      <!-- VIDEO -->
      <video
        v-if="file"
        ref="video"
        class="h-full w-full"
        :src="file.permalink"
        :controls="controls"
        :autoplay="autoplay"
        :loop="loop"
        :muted="muted"
      />
      <!-- YOUTUBE -->
      <YouTube
        v-else-if="videoUrl && initialStartVideo"
        ref="youtube"
        :src="videoUrl"
        :vars="playerVars"
        width="100%"
        height="100%"
        style="width: 100%; height: 100%"
        class="rounded-lg"
        @playing="isPlaying = true"
      />
      <!-- OVERLAY -->
      <transition name="fade-500">
        <button
          v-if="!videoIsStarted"
          class="group absolute inset-0 z-10 overflow-hidden"
          aria-label="Start the video"
          @click="startVideo()"
        >
          <AppImage
            v-if="overlayImage"
            loading="lazy"
            class="h-full w-full object-cover"
            :style="`object-position: ${overlayImage.focus_css};`"
            :src="overlayImage"
          />

          <img
            v-else-if="youtubeThumbnail"
            loading="lazy"
            class="h-full w-full object-cover duration-1000 ease-in-out group-hover:scale-105"
            :src="youtubeThumbnail"
          />

          <!-- ICON -->
          <div
            class="absolute left-1/2 top-1/2 z-10 h-10 w-10 -translate-x-1/2 -translate-y-1/2"
          />
          <PlayIcon
            class="absolute left-1/2 top-1/2 z-20 h-12 w-12 -translate-x-1/2 -translate-y-1/2 text-main-blue transition-colors group-hover:text-main-blue-hover md:h-24 md:w-24"
          />
        </button>
      </transition>
    </div>
    <figcaption
      v-if="caption"
      class="typo-100 mt-3"
      :class="{ hidden: !showCaption }"
    >
      {{ caption }}
    </figcaption>
  </figure>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: null,
    },

    videoUrl: {
      type: String,
      default: null,
    },

    overlayImage: {
      type: Object,
      default: null,
    },

    caption: {
      type: String,
      default: null,
    },

    showCaption: {
      type: Boolean,
      default: false,
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    controls: {
      type: Boolean,
      default: true,
    },

    loop: {
      type: Boolean,
      default: false,
    },

    muted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      videoIsStarted: false,
      youtubeThumbnail: null,
      initialStartVideo: false,
    }
  },

  computed: {
    video() {
      return this.$refs.video
    },

    youtubePlayer() {
      return this.$refs.youtube?.player
    },

    videoId() {
      return this.extractVideoID(this.videoUrl)
    },

    playerVars() {
      return {
        controls: this.controls,
        loop: this.loop,
        modestbranding: 1,
        showinfo: 0,
        muted: this.muted,
        rel: 0,
        cc_load_policy: 1,
        listType: 'playlist',
        autoplay: true,
        playlist: this.videoId,
        noCookie: true,
      }
    },
  },

  async mounted() {
    if (this.autoplay) {
      this.initialStartVideo = true
      this.videoIsStarted = true
    }

    if (this.videoUrl && !this.overlayImage) {
      this.youtubeThumbnail = `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg`
      const imageWidth = await this.getImgWidth()
      // sometimes the youtube max res image is not available
      // it will return instead an error image that has a width of exact 120
      // when it returns that error image it should get instead the hqdefault image
      if (imageWidth === 120) {
        this.youtubeThumbnail = `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`
      }
    }
  },

  methods: {
    startVideo() {
      this.video?.play()
      this.initialStartVideo = true
      // this.youtubePlayer?.playVideo()

      this.videoIsStarted = true
    },

    getImgWidth() {
      return new Promise((resolve) => {
        const newImg = new Image()

        newImg.onload = function () {
          resolve(newImg.width)
        }

        newImg.src = this.youtubeThumbnail
      })
    },

    extractVideoID(url) {
      if (!url) {
        return null
      }

      // Regular expression to match various YouTube URL formats
      const regex =
        /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

      const match = url.match(regex)
      return match && match[1]
    },
  },
}
</script>
