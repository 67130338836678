import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_LcKgStRyi6 from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import motion_fmZWau4t0U from "/vercel/path0/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_jBToDIZpFa from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gsap_qE0fizvEy0 from "/vercel/path0/plugins/gsap.ts";
import livePreview_2CfOB765FN from "/vercel/path0/plugins/livePreview.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import utils_EEhlE5KdlH from "/vercel/path0/plugins/utils.ts";
import veeValidate_U1l0qzVbBC from "/vercel/path0/plugins/veeValidate.ts";
import vue_cloudinary_image_statamic_CYF7m5qqCy from "/vercel/path0/plugins/vue-cloudinary-image-statamic.js";
import vue_cloudinary_image_AN6cd3OYHt from "/vercel/path0/plugins/vue-cloudinary-image.js";
import vue3_youtube_85VsOCUAX8 from "/vercel/path0/plugins/vue3-youtube.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_client_LcKgStRyi6,
  motion_fmZWau4t0U,
  plugin_jBToDIZpFa,
  chunk_reload_client_UciE0i6zes,
  gsap_qE0fizvEy0,
  livePreview_2CfOB765FN,
  sentry_client_GoGQuZo4Et,
  utils_EEhlE5KdlH,
  veeValidate_U1l0qzVbBC,
  vue_cloudinary_image_statamic_CYF7m5qqCy,
  vue_cloudinary_image_AN6cd3OYHt,
  vue3_youtube_85VsOCUAX8
]