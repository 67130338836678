import { defineRule, configure } from 'vee-validate'

import { required, email, numeric, max, url, image } from '@vee-validate/rules'

export default defineNuxtPlugin(() => {
  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)
  defineRule('max', max)
  defineRule('url', url)
  defineRule('image', image)

  defineRule('phone', (value) => {
    // Field is empty, should pass
    if (!value || !value.length) {
      return true
    }
    const regex = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/
    return regex.test(value)
  })

  // to handle the nullable fields rules from statamic
  defineRule('nullable', () => {
    return true
  })

  // register locales
  configure({
    // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnBlur: true,
    // controls if `change` events should trigger validation with `handleChange` handler
    validateOnChange: true,
    // controls if `input` events should trigger validation with `handleChange` handler
    validateOnInput: true,
    // controls if `update:modelValue` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true,
  })
})
