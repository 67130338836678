<template>
  <NuxtLink
    :to="to"
    class="focus-default font-sans-heavy-900 -m-1 rounded-[1.1875rem] p-1 text-sm leading-[1.375rem] ring-offset-0 transition-colors sm:rounded-[1.28125rem] sm:text-base sm:leading-[1.5625rem]"
    :class="
      isWhite
        ? 'text-white hover:text-second-blue-400 visited:text-white'
        : 'text-main-blue hover:text-main-blue-hover visited:text-main-blue'
    "
    :target="$isExternalUrl(to) ? '_blank' : ''"
  >
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },

    isWhite: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
