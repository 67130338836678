<template>
  <div class="max-w-[100vw]">
    <div
      class="relative z-0 mx-auto flex aspect-[1362/778] w-full max-w-[100rem] items-center justify-center overflow-x-visible"
      :class="
        componentIndex === 1 && heroType === 'hero_with_image'
          ? 'mt-[-40%] xs:mt-[-10vh] sm:mt-[-10vh] 2xl:mt-[-20vh] mb-20 sm:mb-24 xl:mb-28 '
          : 'my-20 sm:my-24 xl:my-28'
      "
    >
      <BubbleBlueIcon class="absolute inset-y-0 h-full" />

      <ContainerX class="relative py-36">
        <div class="mx-auto max-w-4xl text-center text-white">
          <h2
            class="default-component-title mb-6 sm:mb-8"
            v-html="$replaceHtmlTags(data.heading)"
          />
          <InViewAnimate>
            <div class="text-base sm:text-md" v-html="data.text" />
          </InViewAnimate>
        </div>
      </ContainerX>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['heroType'],
  props: {
    data: {
      type: Object,
      required: true,
    },

    componentIndex: {
      type: Number,
      default: 0,
    },
  },
}
</script>
