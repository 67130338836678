<template>
  <div class="flex min-h-screen flex-col justify-between">
    <!-- NAVIGATION -->
    <Navigation class="hidden xl:flex" />
    <NavigationMobile class="xl:hidden" />
    <!-- CONTENT -->
    <main>
      <NuxtPage />
    </main>

    <!-- FOOTER -->
    <Footer />
  </div>
</template>

<script setup>
import { useMainStore } from '~/stores/main'
const { $patch } = useMainStore()

// INITIAL
const { data } = await useAsyncGql({
  operation: 'NavigationAndGlobals',
})

if (data.value) {
  $patch({
    navigation: data.value.navigation?.tree,
    footer: data.value.footer?.tree,
    general: data.value.general,
    teaser_registration_defaults: data.value.teaser_registration_defaults,
    social_media: data.value.social_media?.social_media_links,
    seo_defaults: data.value.seo_defaults,
  })
} else {
  console.error('Could not fetch navigation and globals in app.vue')
}
</script>
