<template>
  <div>
    <!-- HERO -->
    <Component
      :is="hero.type.replaceAll('_', '-')"
      v-if="hero?.type"
      :data="hero"
      is-hero
      :first-component="firstComponent"
    />

    <!-- COMPONENTS -->
    <ComponentsLoader
      v-if="page.replicator_components"
      :components="page.replicator_components"
    />
  </div>
</template>

<script>
export default {
  provide() {
    return {
      heroType: this.hero?.type,
    }
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hero() {
      return this.page.hero[0] || {}
    },

    firstComponent() {
      if (
        !Array.isArray(this.page.replicator_components) ||
        !this.page.replicator_components.length
      ) {
        return {}
      }
      return this.page.replicator_components[0]
    },
  },
}
</script>
