export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"stylesheet","href":"https://unpkg.com/maplibre-gl@3.1.0/dist/maplibre-gl.css"}],"style":[],"script":[{"rel":"preload","as":"script","src":"https://unpkg.com/maplibre-gl@3.1.0/dist/maplibre-gl.js"}],"noscript":[],"htmlAttrs":{"lang":"en"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0"}

export const appPageTransition = {"name":"page"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null