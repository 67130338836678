import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordion, LazyAppButton, LazyAppButtonSmall, LazyAppLink, LazyBurgerButton, LazyColorFulText, LazyColumnCard, LazyComponentsLoader, LazyContainerX, LazyInViewAnimate, LazyInViewAnimateMultiple, LazyInputCountries, LazyInputField, LazyInputPhone, LazyLocationsList, LazyLocationsMap, LazyPartnerLogoRow, LazyScrolly, LazySectionY, LazySwiperButtons, LazyTeaserTextAndImage, LazyTestimonial, LazyTransitionExpand, LazyVideoPlayer, LazyBlueprintPage, LazyHeroHome, LazyHeroWithImage, LazyArrowRoundIcon, LazyArrowSimpleIcon, LazyBubbleBlueIcon, LazyCheckmarkIcon, LazyCloudTopIcon, LazyCrossIcon, LazyDeliSkyIcon, LazyPlayIcon, LazyQuoteIcon, LazyScrollyIcon, LazySickleIcon, LazySpinIcon, LazyFooter, LazyNavigation, LazyNavigationMobile, LazyAccordionGroup, LazyColumnCards, LazyImageOrVideo, LazyLeadText, LazyLocationsMapAndList, LazyPartnerLogos, LazyRegistrationFrom, LazyTeaserRegistration, LazyTeasersTextAndImage, LazyTestimonials, LazyTextAndButton } from '#components'
const lazyGlobalComponents = [
  ["Accordion", LazyAccordion],
["AppButton", LazyAppButton],
["AppButtonSmall", LazyAppButtonSmall],
["AppLink", LazyAppLink],
["BurgerButton", LazyBurgerButton],
["ColorFulText", LazyColorFulText],
["ColumnCard", LazyColumnCard],
["ComponentsLoader", LazyComponentsLoader],
["ContainerX", LazyContainerX],
["InViewAnimate", LazyInViewAnimate],
["InViewAnimateMultiple", LazyInViewAnimateMultiple],
["InputCountries", LazyInputCountries],
["InputField", LazyInputField],
["InputPhone", LazyInputPhone],
["LocationsList", LazyLocationsList],
["LocationsMap", LazyLocationsMap],
["PartnerLogoRow", LazyPartnerLogoRow],
["Scrolly", LazyScrolly],
["SectionY", LazySectionY],
["SwiperButtons", LazySwiperButtons],
["TeaserTextAndImage", LazyTeaserTextAndImage],
["Testimonial", LazyTestimonial],
["TransitionExpand", LazyTransitionExpand],
["VideoPlayer", LazyVideoPlayer],
["BlueprintPage", LazyBlueprintPage],
["HeroHome", LazyHeroHome],
["HeroWithImage", LazyHeroWithImage],
["ArrowRoundIcon", LazyArrowRoundIcon],
["ArrowSimpleIcon", LazyArrowSimpleIcon],
["BubbleBlueIcon", LazyBubbleBlueIcon],
["CheckmarkIcon", LazyCheckmarkIcon],
["CloudTopIcon", LazyCloudTopIcon],
["CrossIcon", LazyCrossIcon],
["DeliSkyIcon", LazyDeliSkyIcon],
["PlayIcon", LazyPlayIcon],
["QuoteIcon", LazyQuoteIcon],
["ScrollyIcon", LazyScrollyIcon],
["SickleIcon", LazySickleIcon],
["SpinIcon", LazySpinIcon],
["Footer", LazyFooter],
["Navigation", LazyNavigation],
["NavigationMobile", LazyNavigationMobile],
["AccordionGroup", LazyAccordionGroup],
["ColumnCards", LazyColumnCards],
["ImageOrVideo", LazyImageOrVideo],
["LeadText", LazyLeadText],
["LocationsMapAndList", LazyLocationsMapAndList],
["PartnerLogos", LazyPartnerLogos],
["RegistrationFrom", LazyRegistrationFrom],
["TeaserRegistration", LazyTeaserRegistration],
["TeasersTextAndImage", LazyTeasersTextAndImage],
["Testimonials", LazyTestimonials],
["TextAndButton", LazyTextAndButton]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
