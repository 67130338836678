<template>
  <div class="mt-10 max-w-4xl rounded-3xl shadow-custom">
    <!-- IMAGE -->
    <div>
      <AppImage
        v-if="testimonial.image"
        class="mx-auto h-16 w-16 -translate-y-1/2 rounded-full object-cover sm:h-20 sm:w-20"
        :aspect-ratio="1"
        :style="`object-position: ${testimonial.image.focus_css};`"
        :src="testimonial.image"
        loading="lazy"
      />
    </div>
    <div
      class="px-container pb-container text-sm sm:px-14 sm:pb-14 sm:text-base"
      :class="testimonial.image ? 'pt-3 sm:pt-7' : 'pt-container sm:pt-14'"
    >
      <!-- TEXT -->
      <div v-if="testimonial.testimonial" class="relative">
        <QuoteIcon
          class="left-0 top-0 mb-container h-5 w-5 shrink-0 text-main-blue sm:absolute sm:mb-0"
        />
        <div
          class="pl-0 text-sm sm:pl-14 sm:text-md"
          v-html="testimonial.testimonial"
        />
        <QuoteIcon
          class="ml-auto mr-container h-5 w-5 shrink-0 rotate-180 text-main-blue sm:mr-14"
        />
      </div>

      <!-- NAME & POSITION -->
      <div
        class="mx-auto mt-9 w-fit font-sans-heavy-900 text-sm sm:mt-12 sm:text-base"
      >
        <Component :is="hasHeading ? 'h3' : 'h2'" class="inline">
          {{ testimonial.title }}
        </Component>
        <template v-if="testimonial.position">
          <p class="inline">, {{ testimonial.position }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testimonial: {
      type: Object,
      required: true,
    },

    hasHeading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
