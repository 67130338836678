<template>
  <div
    class="pointer-events-none absolute inset-y-0 z-40 flex w-full overflow-hidden"
  >
    <nav
      class="pointer-events-auto flex h-navigation-mobile w-full items-center justify-between gap-10 px-container xl:h-navigation"
      @keyup.esc="navOpen = false"
    >
      <!-- HOME -->
      <NuxtLink to="/" aria-label="Home" class="focus-default -ml-3 rounded">
        <DeliSkyIcon class="relative z-[70] w-32" />
      </NuxtLink>

      <div class="z-[70] flex space-x-6 sm:space-x-11">
        <template v-for="item in navigation" :key="item.page?.id">
          <AppButtonSmall
            v-if="!item.page.entry_id"
            :aria-label="`Go to ${item?.page.title}`"
            :to="item.page?.url"
          >
            {{ item?.page.title }}
          </AppButtonSmall>
        </template>

        <div class="relative">
          <BurgerButton
            :open="navOpen"
            class="focus-default absolute top-1/2 block w-6 -translate-y-1/2 rounded sm:w-9"
            aria-label="Button to open and close navigation"
            @click="toggleNav()"
            @focusout="navOpen = false"
          />
        </div>
      </div>

      <!-- BACKGROUND -->
      <transition name="fade-500">
        <div
          v-show="navOpen"
          class="fixed inset-0 bg-second-blue-800/50"
          @click="navOpen = false"
        />
      </transition>

      <!-- PAGE LINKS -->
      <div
        class="absolute inset-x-0 top-0 max-h-screen origin-top-right transition-all duration-500 ease-out"
        :class="{
          'translate-x-[5%] translate-y-3 rotate-[25deg] scale-[0.3] xs:translate-y-7 xs:scale-[0.25] sm:translate-y-10 sm:scale-[0.2]':
            !navOpen,
          'overflow-y-auto overflow-x-hidden': openAndAnimEnd,
        }"
        @focusin="navOpen = true"
        @focusout="navOpen = false"
      >
        <div
          class="relative mb-[-66%] ml-[-39%] w-[146%] rounded-b-[10%] bg-white px-container pl-[40%] pr-[7%] pt-32"
        >
          <transition name="fade-500">
            <div
              v-if="openAndAnimEnd"
              class="small-gradient fixed inset-x-0 top-0 z-[60] h-navigation-mobile"
            />
          </transition>

          <TransitionExpand
            class="w-full overflow-hidden transition-all duration-500 ease-out"
            :open="navOpen"
            @opened="openAndAnimEnd = true"
            @closed="openAndAnimEnd = false"
          >
            <ul
              class="relative z-50 flex w-full flex-col items-center space-y-12 overflow-hidden py-1 transition-all duration-500 ease-out"
            >
              <li
                v-for="item in navigation"
                :key="item.page?.id"
                v-motion
                :initial="{
                  y: 50,
                  opacity: 0,
                }"
                :visible="{
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 500,
                    ease: 'easeOut',
                    delay: 400,
                  },
                }"
              >
                <NuxtLink
                  v-if="item.page.entry_id"
                  :to="item.page?.url"
                  class="focus-default font-sans-heavy-900 z-50 rounded-[1em] text-center text-lg transition-colors hover:text-main-blue sm:max-w-[60%]"
                  :aria-label="`Go to ${item?.page.title}`"
                >
                  {{ item?.page.title }}
                </NuxtLink>
              </li>
            </ul>
          </TransitionExpand>
        </div>

        <!-- BUBBLES CLIP PATH -->
        <svg class="absolute h-0 w-0">
          <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
            <path
              d="M0.349,0.799 A0.289,0.369,0,0,1,0.289,0.807 C0.129,0.807,0,0.642,0,0.437 S0.129,0.067,0.289,0.067 a0.29,0.37,0,0,1,0.049,0.005 l0.625,-0.072,-0.006,0.325 A0.354,0.452,0,0,1,1,0.545 c0,0.251,-0.159,0.455,-0.356,0.455 a0.355,0.454,0,0,1,-0.295,-0.201"
            />
          </clipPath>
        </svg>

        <div
          class="clipped relative inset-x-0 bottom-0 ml-[-40%] aspect-[1.278] w-[147%]"
        />
      </div>
    </nav>
  </div>
</template>

<script>
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'

export default {
  setup() {
    const main = useMainStore()
    const { navigation } = storeToRefs(main)
    return {
      navigation,
    }
  },

  data() {
    return {
      navOpen: false,
      openAndAnimEnd: false,
    }
  },

  watch: {
    $route() {
      this.navOpen = false
      document.body.style.overflow = 'auto'
    },

    navOpen() {
      if (this.navOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
  },

  methods: {
    toggleNav() {
      this.navOpen = !this.navOpen
    },
  },
}
</script>

<style lang="scss">
.router-link-exact-active,
.router-link-active {
  @apply text-main-blue;
}

.clipped {
  background: linear-gradient(185deg, rgba(255, 255, 255, 1) 0%, #e7f5ff 100%);
  background-size: cover;
  -webkit-clip-path: url(#my-clip-path);
  clip-path: url(#my-clip-path);
}

.small-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );
}
</style>
