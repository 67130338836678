<template>
  <SectionY :padding="data.padding?.value">
    <ContainerX is-max>
      <div class="mx-auto mb-5 max-w-component sm:mb-0">
        <h2
          v-if="data.heading"
          class="default-component-title mx-auto mb-14 xl:mb-20"
        >
          <span v-html="$replaceHtmlTags(data.heading)" />
        </h2>

        <div class="space-y-20 sm:space-y-24 xl:space-y-28">
          <div v-for="(item, index) in data.images_or_videos" :key="index">
            <!-- IMAGE -->
            <InViewAnimate>
              <AppImage
                v-if="item.image"
                loading="lazy"
                class="w-full rounded-[0.625rem] sm:rounded-[1.25rem]"
                :src="item.image"
              />

              <!-- VIDEO -->
              <VideoPlayer
                v-else-if="item.video"
                :video-url="item.video"
                class="overflow-hidden rounded-[0.625rem] sm:rounded-[1.25rem]"
              />
            </InViewAnimate>
          </div>
        </div>
      </div>
    </ContainerX>
  </SectionY>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
