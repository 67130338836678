<template>
  <footer
    class="before:translate relative min-h-[20rem] w-full rounded-t-[2.5rem] bg-white px-container pb-10 pt-12 before:absolute before:inset-x-0 before:top-[calc(-50%+1.9rem)] before:-z-10 before:h-[50%] before:bg-gradient-to-t before:from-second-blue-200 sm:px-container-sm sm:before:top-[calc(-200%+1.9rem)] sm:before:h-[200%]"
  >
    <!-- HIDDEN H2 TITLE -->
    <h2 class="pointer-events-none h-0 w-0 opacity-0">Footer</h2>

    <div class="flex w-full flex-col lg:flex-row lg:gap-12">
      <!-- HOME -->
      <NuxtLink
        to="/"
        aria-label="Home"
        class="focus-default mb-12 w-fit rounded"
      >
        <DeliSkyIcon class="w-32 sm:w-44" />
      </NuxtLink>

      <!-- CONTACT -->
      <div
        class="flex w-full max-w-5xl flex-col justify-between gap-11 pt-5 text-base sm:flex-row sm:text-md lg:px-container-sm xl:px-container-xl"
      >
        <div>
          <h3 class="font-sans-heavy-900 mb-2">Contact</h3>
          <AppLink
            class="!font-sans !text-base !font-normal sm:!text-md"
            :to="`tel:${general.phone}`"
            >{{ general.phone }}</AppLink
          >
        </div>
        <div>
          <h3 class="font-sans-heavy-900 mb-2">Support</h3>
          <AppLink
            class="!font-sans !text-base !font-normal sm:!text-md"
            :to="`mailto:${general.email}`"
            >{{ general.email }}</AppLink
          >
        </div>
        <div>
          <h3 class="font-sans-heavy-900 mb-2">Address</h3>

          <a
            :href="general.address_link"
            target="_blank"
            class="focus-default inline-block rounded"
          >
            <address
              class="whitespace-pre-wrap not-italic"
              v-html="general.address"
            />
          </a>
        </div>
      </div>
    </div>

    <!-- NAVIGATION -->
    <div
      class="mt-16 flex flex-col gap-16 sm:mt-20 sm:flex-row sm:items-center sm:justify-between sm:gap-11"
    >
      <nav>
        <ul class="-mx-4 flex flex-col gap-3 sm:flex-row sm:gap-0">
          <li
            v-for="(item, index) in footer"
            :key="item.page?.id"
            class="relative px-4 after:opacity-60 sm:after:absolute sm:after:inset-y-[0.375rem] sm:after:right-0 sm:after:w-[0.0925rem] sm:after:rounded-full sm:after:bg-[#747780]"
            :class="{ 'after:hidden': index === footer.length - 1 }"
          >
            <NuxtLink
              :to="item.page?.url"
              class="focus-default rounded-[1em] text-sm text-[#747780] transition-colors hover:text-main-blue"
              :aria-label="`Go to ${item?.page.title}`"
            >
              {{ item?.page.title }}
            </NuxtLink>
          </li>
        </ul>
      </nav>

      <!-- SOCIAL MEDIA -->
      <ul class="flex flex-wrap gap-7">
        <li v-for="(media, index) in socialMedia" :key="index">
          <NuxtLink
            class="focus-default inline-block rounded transition-colors"
            :target="$isExternalUrl(media.link) ? '_blank' : ''"
            :to="media.link"
            :aria-label="`Link zu ${media.heading}`"
          >
            <AppImage
              v-if="media.icon"
              class="black-to-blue-filter h-5 w-5"
              :src="media.icon"
              loading="lazy"
            />
          </NuxtLink>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
export default {
  setup() {
    const main = useMainStore()
    const { footer, general, social_media } = storeToRefs(main)
    return {
      footer,
      general,
      socialMedia: social_media,
    }
  },
}
</script>

<style lang="scss" scoped>
.black-to-blue-filter {
  filter: invert(38%) sepia(96%) saturate(3986%) hue-rotate(206deg)
    brightness(100%) contrast(103%);
}
.black-to-blue-filter:hover {
  filter: invert(48%) sepia(92%) saturate(6799%) hue-rotate(208deg)
    brightness(95%) contrast(97%);
}
</style>
