<template>
  <details>
    <summary
      ref="summary"
      class="focus-default flex cursor-pointer list-none justify-between rounded-sm"
      @click.prevent="preventClick ? null : onClickSummary()"
    >
      <slot name="summary" />
      <ArrowSimpleIcon
        v-if="!hideArrow"
        class="mx-5 my-7 h-4 w-3 shrink-0 grow-0 text-main-blue transition-transform sm:mx-6 sm:my-8"
        :style="[
          isClosing ? `transition-duration: ${durationClose}ms` : null,
          isExpanding ? `transition-duration: ${durationOpen}ms` : null,
        ]"
        :class="{
          'rotate-180': isExpanding || $el?.open,
          '!rotate-0': isClosing || !$el?.open,
        }"
      />
    </summary>
    <div ref="content">
      <slot name="content" />
    </div>
  </details>
</template>

<script>
// https://css-tricks.com/how-to-animate-the-details-element-using-waapi/
export default {
  props: {
    durationOpen: {
      type: Number,
      default: 400,
    },

    durationClose: {
      type: Number,
      default: 250,
    },

    preventClick: {
      type: Boolean,
      default: false,
    },

    isOpen: {
      type: Boolean,
      default: false,
    },

    hideArrow: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      animation: null,
      isClosing: false,
      isExpanding: false,
    }
  },

  watch: {
    isOpen() {
      if (this.isOpen && (this.isClosing || !this.$el.open)) {
        this.open()
      } else if (!this.isOpen && (this.isExpanding || this.$el.open)) {
        this.shrink()
      }
    },
  },

  methods: {
    onClickSummary() {
      // Check if the element is being closed or is already closed
      if (this.isClosing || !this.$el.open) {
        this.open()
        // Check if the element is being openned or is already open
      } else if (this.isExpanding || this.$el.open) {
        this.shrink()
      }
    },

    shrink() {
      // Add an overflow on the <details> to avoid content overflowing
      this.$el.style.overflow = 'hidden'
      // Set the element as "being closed"
      this.isClosing = true
      // Store the current height of the element
      const startHeight = `${this.$el.offsetHeight}px`
      // Calculate the height of the summary
      const endHeight = `${this.$refs.summary.offsetHeight}px`

      // If there is already an animation running
      if (this.animation) {
        // Cancel the current animation
        this.animation.cancel()
      }

      // No animation for IOS Safari < 13.4
      if (typeof this.$el.animate === 'undefined') {
        this.$el.style.height = endHeight
        this.onAnimationFinish(false)
        return
      }

      // Start a WAAPI animation
      this.animation = this.$el.animate(
        {
          // Set the keyframes from the startHeight to endHeight
          height: [startHeight, endHeight],
        },
        {
          // If the duration is too slow or fast, you can change it here
          duration: parseInt(this.durationClose),
          // You can also change the ease of the animation
          easing: 'ease-out',
        }
      )

      // When the animation is complete, call onAnimationFinish()
      this.animation.onfinish = () => this.onAnimationFinish(false)
      // If the animation is cancelled, isClosing variable is set to false
      this.animation.oncancel = () => (this.isClosing = false)
    },

    open() {
      // Add an overflow on the <details> to avoid content overflowing
      this.$el.style.overflow = 'hidden'
      // Apply a fixed height on the element
      this.$el.style.height = `${this.$el.offsetHeight}px`
      // Force the [open] attribute on the details element
      this.$el.open = true
      // Wait for the next frame to call the expand function
      window.requestAnimationFrame(() => this.expand())
    },

    expand() {
      // Set the element as "being expanding"
      this.isExpanding = true
      // Get the current fixed height of the element
      const startHeight = `${this.$el.offsetHeight}px`
      // Calculate the open height of the element (summary height + content height)
      const endHeight = `${
        this.$refs.summary.offsetHeight + this.$refs.content.offsetHeight
      }px`

      // If there is already an animation running
      if (this.animation) {
        // Cancel the current animation
        this.animation.cancel()
      }

      // No animation for IOS Safari < 13.4
      if (typeof this.$el.animate === 'undefined') {
        this.$el.style.height = endHeight
        this.onAnimationFinish(true)
        return
      }

      // Start a WAAPI animation
      this.animation = this.$el.animate(
        {
          // Set the keyframes from the startHeight to endHeight
          height: [startHeight, endHeight],
        },
        {
          // If the duration is too slow of fast, you can change it here
          duration: parseInt(this.durationOpen),
          // You can also change the ease of the animation
          easing: 'ease-out',
        }
      )
      // When the animation is complete, call onAnimationFinish()
      this.animation.onfinish = () => this.onAnimationFinish(true)
      // If the animation is cancelled, isExpanding variable is set to false
      this.animation.oncancel = () => (this.isExpanding = false)
    },

    onAnimationFinish(open) {
      // Set the open attribute based on the parameter
      this.$el.open = open
      // Clear the stored animation
      this.animation = null
      // Reset isClosing & isExpanding
      this.isClosing = false
      this.isExpanding = false
      // Remove the overflow hidden and the fixed height
      this.$el.style.height = this.$el.style.overflow = ''

      if (open) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    },
  },
}
</script>

<style scoped>
details summary::-webkit-details-marker {
  display: none;
}
</style>
