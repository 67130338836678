<template>
  <div
    class="relative p-6"
    :class="{ 'sm:ml-container-sm xl:ml-container-xl': isSlider }"
  >
    <div class="relative flex">
      <div v-if="card.image" class="mb-12 mr-10 shrink-0 sm:mb-16">
        <AppImage
          class="h-36 w-auto bg-white sm:h-44"
          :class="{ 'select-none': isSlider }"
          :src="card.image"
          loading="lazy"
        />
      </div>

      <!-- DOTTED LINE -->
      <div
        v-if="hasDottedConnectionLines && card.image"
        class="-mr-20 mt-5 h-[4.5rem] w-full min-w-[165px] overflow-hidden"
        :class="{
          '-ml-8 -mr-24 rotate-180 place-self-end': index % 2 !== 0,
          'sm:hidden xl:block': (index + 1) % 2 === 0 && !isSlider,
          'xl:hidden 3xl:block': (index + 1) % 3 === 0 && !isSlider,
          '3xl:hidden': (index + 1) % 4 === 0 && !isSlider,
          '!hidden': index === amountCards - 1,
        }"
      >
        <div
          class="mx-[-8%] h-36 w-[116%] rounded-[50%] border-x-2 border-t-2 border-dashed border-second-blue-300 sm:border-x-[0.1875rem] sm:border-t-[0.1875rem]"
        />
      </div>
    </div>

    <!-- TEXT -->
    <div
      :class="{
        'pr-container sm:pr-container-sm xl:pr-container-xl': isSlider,
      }"
    >
      <div class="relative">
        <CheckmarkIcon class="absolute -left-6 top-2 w-4 text-main-green" />
        <Component
          :is="hasHeading ? 'h3' : 'h2'"
          class="font-sans-heavy-900 mb-3 text-md sm:text-lg"
        >
          <span v-html="$replaceHtmlTags(card.title)" />
        </Component>
      </div>

      <div class="text-sm sm:text-base" v-html="card.text" />

      <AppLink
        v-if="card.link"
        class="mt-5 inline-block"
        :to="`${card.link}#${card.component_id}`"
      >
        {{ card.anchor_link_text }}
      </AppLink>

      <button
        v-else-if="card.component_id"
        class="focus-default font-sans-heavy-900 -m-1 mt-5 inline-block w-fit rounded-[1.1875rem] p-1 text-sm leading-[1.375rem] text-main-blue ring-current ring-offset-0 transition-colors hover:text-main-blue-hover sm:rounded-[1.28125rem] sm:text-base sm:leading-[1.5625rem]"
        @click="scrollTo(card.component_id)"
      >
        {{ card.anchor_link_text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    amountCards: {
      type: Number,
      default: 1,
    },

    hasHeading: {
      type: Boolean,
      default: false,
    },

    isSlider: {
      type: Boolean,
      default: false,
    },

    hasDottedConnectionLines: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    scrollTo(id) {
      const el = document.getElementById(id)
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
</script>
