<template>
  <nav
    class="absolute top-0 z-40 flex h-navigation-mobile w-full items-center justify-between gap-10 overflow-hidden px-14 xl:h-navigation"
  >
    <!-- HOME -->
    <NuxtLink to="/" aria-label="Home" class="focus-default -ml-3 rounded">
      <DeliSkyIcon class="w-44" />
    </NuxtLink>

    <!-- PAGE LINKS -->
    <ul class="flex items-center gap-9">
      <li v-for="item in navigation" :key="item.page?.id">
        <NuxtLink
          v-if="item.page.entry_id"
          :to="item.page?.url"
          class="focus-default rounded-[1em] text-center font-sans-heavy-900 text-md transition-colors hover:text-main-blue"
          :aria-label="`Go to ${item?.page.title}`"
        >
          {{ item?.page.title }}
        </NuxtLink>
        <AppButtonSmall
          v-if="!item.page.entry_id"
          :aria-label="`Go to ${item?.page.title}`"
          :to="item.page?.url"
          target="_self"
        >
          {{ item?.page.title }}
        </AppButtonSmall>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useMainStore } from '@/stores/main'
import { storeToRefs } from 'pinia'
export default {
  setup() {
    const main = useMainStore()
    const { navigation } = storeToRefs(main)
    return {
      navigation,
    }
  },
}
</script>

<style lang="scss">
.router-link-exact-active,
.router-link-active {
  @apply text-main-blue;
}
</style>
