<template>
  <section
    :class="{
      'py-20 sm:py-24 xl:py-28': padding === 'y',
      'pt-20 sm:pt-24 xl:pt-28': padding === 't',
      'pb-20 sm:pb-24 xl:pb-28': padding === 'b',
      '': padding === 'none',
    }"
  >
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    padding: {
      type: String,
      default: 'y',
    },
  },
}
</script>
