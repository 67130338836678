/* eslint-disable no-undef */
export const useMainStore = defineStore('main', {
  state: () => ({
    navigation: {},
    footer: {},
    general: {},
    teaser_registration_defaults: {},
    social_media: {},
    seo_defaults: {},
  }),
})
