<template>
  <button class="group relative aspect-[24/16] text-main-blue">
    <div
      class="absolute top-0 h-[15%] w-full rounded-full bg-current transition-top-bottom-transform duration-700"
      :class="{ 'top-1/2 -translate-y-1/2 -rotate-45': open }"
    />
    <div
      class="absolute top-1/2 left-1/2 h-[15%] w-full -translate-y-1/2 -translate-x-1/2 rounded-full bg-current transition-[width] duration-700"
      :class="{ '!w-0': open }"
    />
    <div
      class="absolute bottom-0 h-[15%] w-full rounded-full bg-current transition-top-bottom-transform duration-700"
      :class="{ 'bottom-1/2 translate-y-1/2 rotate-45': open }"
    />
  </button>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
