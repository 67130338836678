<template>
  <Component
    :is="buttonOrNuxtLink"
    v-bind="to ? { to: to } : {}"
    :target="target ? target : $isExternalUrl(to) ? '_blank' : ''"
    class="focus-default font-sans-heavy-900 inline-block rounded-[0.975rem] bg-second-blue-600 px-[1.125rem] py-2 text-xs leading-[0.875rem] text-white ring-second-blue-600 transition-colors visited:text-white hover:bg-main-blue-hover hover:ring-main-blue-hover sm:rounded-[1.1875rem] sm:px-[1.375rem] sm:text-sm sm:leading-[1.375rem]"
  >
    <slot />
  </Component>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
  target: { type: String, default: null },
})

// determine whether to load NuxtLink or button
const buttonOrNuxtLink = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }

  return 'button'
})
</script>
