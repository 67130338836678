<template>
  <transition
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <div v-show="selected" ref="content" v-bind="$attrs">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: this.open,
    }
  },

  watch: {
    open(value) {
      this.selected = value
    },
  },

  methods: {
    toggle() {
      this.selected = !this.selected
      if (this.selected) {
        this.$emit('open')
      }
    },

    async close() {
      await this.$nextTick()
      if (this.selected) {
        this.selected = false
      }
    },

    beforeEnter() {
      this.$refs.content.style.height = '0'
    },

    enter() {
      this.$refs.content.style.height = this.$refs.content.scrollHeight + 'px'
    },

    afterEnter() {
      this.$emit('opened')
      this.$refs.content.style.opacity = '1'
    },

    beforeLeave() {
      this.$refs.content.style.opacity = '1'
      this.$refs.content.style.height = this.$refs.content.scrollHeight + 'px'
      // Required to force recalculation. Without it first closing is not smooth.
      // eslint-disable-next-line
      this.$refs.content.scrollHeight
    },

    leave() {
      this.$emit('closed')
      this.$refs.content.style.height = '0'
    },

    setHeight(height) {
      this.$refs.content.style.height = height + 'px'
    },
  },
}
</script>
