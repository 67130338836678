<template>
  <header
    class="relative z-10 mb-20 flex flex-col pt-navigation-mobile sm:mb-24 sm:pt-navigation lg:min-h-[60vh] xl:mb-28"
  >
    <div
      class="grid grow grid-cols-1 gap-4 pt-20 lg:grid-cols-2 lg:gap-0 lg:pt-32"
    >
      <!-- TEXT -->
      <div class="px-container sm:px-container-sm lg:pb-32 xl:px-container-xl">
        <InViewAnimate>
          <h1
            class="font-sans-heavy-900 mb-5 max-w-title text-2xl sm:text-3xl"
            v-html="$replaceHtmlTags(data.heading)"
          />
        </InViewAnimate>
        <InViewAnimate :delay="200">
          <div
            class="text ul-list-style max-w-[65ch] text-base sm:text-md"
            v-html="data.text"
          />
        </InViewAnimate>
      </div>

      <!-- IMAGE -->
      <div
        class="relative mt-5 max-h-screen w-full px-container sm:px-container-sm lg:-mb-8 lg:mt-0 xl:pl-14 xl:pr-container-max"
      >
        <AppImage
          :src="data.image"
          class="h-full w-full rounded-lg object-cover"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.hero-list-style {
  ul {
    list-style: url(~/assets/images/checkmark.svg);
    transform: translateX(1.25em);
    margin: 1.5rem 0rem 1.5rem 0rem;

    li {
      margin-bottom: 1rem;
    }

    li::marker {
      font-size: 1.6em;
    }
  }
}
</style>
