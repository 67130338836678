<template>
  <div class="flex justify-between text-main-blue">
    <!-- PREV BUTTON -->
    <div>
      <button
        class="focus-default group rounded-full"
        :class="{ 'opacity-50': isBeginning }"
        :disabled="isBeginning"
        aria-label="Next slide next"
      >
        <div
          :class="{
            'transition-all group-hover:animate-custom-bounce-left group-hover:text-main-blue-hover':
              !isBeginning,
          }"
        >
          <ArrowRoundIcon
            class="h-9 w-9 rotate-90"
            :class="!isBeginning ? '' : ''"
            @click="$emit('prev')"
          />
        </div>
      </button>
    </div>

    <!-- NEX BUTTON -->
    <div>
      <button
        :class="{ 'opacity-50': isEnd }"
        :disabled="isEnd"
        class="focus-default group rounded-full"
        aria-label="Previous slide"
        @click="$emit('next')"
      >
        <div
          :class="{
            'transition-all group-hover:animate-custom-bounce-right group-hover:text-main-blue-hover':
              !isEnd,
          }"
        >
          <ArrowRoundIcon
            class="h-9 w-9 -rotate-90"
            :class="!isEnd ? '' : ''"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<script>
// use this component ether with ref or with props.
// usually use ref on the functions here. It is be less to configure on the parent (Gallery.vue)
// if you render this button multiple times e.g. on each slide use props ref will not work. (TeaserLargeSlider.vue)
// in this case you need to define the functions on the parent and pass them as props to this component

export default {
  props: {
    reachBeginning: {
      type: Boolean,
      default: true,
    },

    reachEnd: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isBeginning: true,
      isEnd: false,
    }
  },

  watch: {
    reachBeginning: {
      immediate: true,
      handler(newVal) {
        this.isBeginning = newVal
      },
    },

    reachEnd: {
      immediate: true,
      handler(newVal) {
        this.isEnd = newVal
      },
    },
  },

  methods: {
    async reachedBeginningOrEnd(beginningOrEnd) {
      // need to wait until fromEdge event has set its values
      await nextTick()

      if (beginningOrEnd === 'beginning') {
        this.isBeginning = true
      } else {
        this.isEnd = true
      }
    },

    fromEdge() {
      this.isBeginning = false
      this.isEnd = false
    },
  },
}
</script>

<style lang="scss">
.swiper-wrapper .swiper-slide:only-child {
  .firstslide\:hidden {
    @apply hidden;
  }
}
</style>
